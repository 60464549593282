@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');

/* base styles */
body {
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  background: #a9d7d5;
  color: #fff;
  text-align: center;
}

.modal{
  backdrop-filter: blur(2px);
  background-color: #a9d7d5b1;
  color: rgb(120, 0, 141);
}

.popup-content {

  background-color: #a9d7d5b1; 
}

.popup-content {  
  margin: auto;  
  background: #a9d7d5b1;
  width: 50%;  
  padding: 5px;
}

.popup-arrow {  color: #a9d7d5b1;}[role='tooltip'].popup-content {  width: 200px;  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;}
.popup-overlay {  background: #a9d7d5b1;}[data-popup='tooltip'].popup-overlay {  background: transparent;}

.top-banner{
  background-image: url("./imgs/banner_001.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  width: 1104px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-banner2{
  background-image: url("./imgs/banner_002_a.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  width: 1104px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-btn-1 {
  background-color: transparent;
  background-image: url("./imgs/boton_001.png");
  background-repeat: no-repeat;
  background-position: contain;
  background-size: contain;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: 234px;
  height: 40px;


}

.top-btn-2 {
  background-color: transparent;
  background-image: url("./imgs/boton_002.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: contain;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: 234px;
  height: 40px;

}

.top-btn-2:hover {

  width: 234px;
  height: 41px;

}

.top-btn-1:hover {

  width: 234px;
  height: 41px;

}

.game {

  background-image: url("./imgs/titulo.png");
  width: 984px;
  height: 555px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  margin-left: auto;
  margin-right: auto;


}

.Vyuu-app{

  display: block;
  margin-left: auto;
  margin-right: auto;

}
.content {

  width: 984px;
  height: 555px;

}

.button {
  background-color: transparent;
  background-image: url("./imgs/jugar.png");
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 40%;
  width: 200px;
  height: 200px;
  background-size: contain;
}

.button2 {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 40%;
  width: 200px;
  height: 50px;
  background-size: contain;
}

.button:hover {
  width: 195px;
  height: 195px;
  
}

.btn-ubicar-play{
  margin-left: 640px;
  padding-top: 80px;

}




