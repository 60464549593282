.App {
  background-image: url("./imgs/FONDO_002.png");
  width: 984px;
  height: 555px;
  background-size: contain;
  background-repeat: no-repeat;
}

.final {
  background-image: url("./imgs/FONDO_003.png");
  width: 984px;
  height: 555px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.button-card {
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.button-card:hover {
  background: #c23866;
  color: #fff;
}
.card-grid {
  display: grid;
  padding-left: 250px;
  padding-top: 100px;
  padding-right: 250px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.respuesta {
  position: fixed;
  margin-left: 767px;
  padding-top: 100px;
}

.res-img-1 {
  height: 0px;
  transition: all ease-in 0.5s;
}

.flipped .res-img-1 {
  height: 210px;
  transition-delay: 0.4s;
}

.final-btn {
  margin-left: 500px;
  padding-top: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 40%;

  background-size: contain;
}

.final-img {
  width: 200px;
  height: 200px;
}

.final-img:hover {
  /* left: 670px; */
  /* top: 19px; */
  width: 195px;
  height: 195px;
}

.final-btn:hover {
  left: 670px;
  top: 19px;
  /* width: 195px;
  height: 195px; */
}
